import {DemoMode, PaymentSystem, TariffMode} from "../../const";
import {ContestTariff, ContestTariffTable} from "../../const/contest-tariff";
import {PaymentRole} from "../../const/payment-role";
import {NTR} from "../../name-to-request";
import {getNameFromText, isQTT, isTT} from "../../utils/common";
import {getIsBaseDocument, getTypeDocument, sortDocuments} from "../../utils/document";
import {sortMailTemplates} from "../../utils/mail-template";
import {parsingOrder} from "../../utils/sort";
import CompereModel from "../compere/model";
import RefereeModel from "../referee/model";
import SoundmanModel from "../soundman/model";
import TerminalModel from "../terminal/model";

const getPaymentSystem = (system) => {
  if (!system) {
    return ``;
  }

  if (system === PaymentSystem.YANDEX) {
    return PaymentSystem.UKASSA;
  }

  return system;
};

export default class ContestParsing {
  static address(data) {
    const address = data.address;
    return (!address || address === `null` || address === NTR.NO_ACCESS) ? `` : address;
  }

  static newContestId(response) {
    return response[NTR.NEW_ID];
  }

  static criteriaId(response) {
    return response[NTR.CRITERIA_ID];
  }

  static nominationId(response) {
    return response[NTR.NOMINATION_ID];
  }

  static landmarkId(response) {
    return response[NTR.LANDMARK_ID];
  }

  static titleLong(data) {
    const title = data.contest_title;

    if (!title || title === `null` || title === NTR.NO_ACCESS || !title.long) {
      return ``;
    }

    return title.long;
  }

  static titleShort(data) {
    const title = data.contest_title;

    if (!title || title === `null` || title === NTR.NO_ACCESS || !title.short) {
      return ``;
    }

    return title.short;
  }

  static date(data) {
    const startDate = data.start_date;
    const finishDate = data.finish_date;

    const start = (!startDate || startDate === NTR.NO_ACCESS) ? `` : startDate;
    const finish = (!finishDate || finishDate === NTR.NO_ACCESS) ? `` : finishDate;

    return {
      start,
      finish: (finish === `` && start !== ``) ? start : finish,
    };
  }

  static deadlineAffimation(data) {
    const affimationDeadline = data.deadline_affirmation;
    return (!affimationDeadline || affimationDeadline === NTR.NO_ACCESS || affimationDeadline === `0` || affimationDeadline === 0 || affimationDeadline === `null` || affimationDeadline === null) ? `` : affimationDeadline;

    // return (!affimationDeadline || affimationDeadline === NTR.NO_ACCESS) ? `` : affimationDeadline;
  }

  static deadlinePayment(data) {
    const paymentDeadline = data.deadline_payment;
    return (!paymentDeadline || paymentDeadline === NTR.NO_ACCESS || paymentDeadline === `0` || paymentDeadline === 0 || paymentDeadline === `null` || paymentDeadline === null) ? `` : paymentDeadline;
  }

  static timeZone(data) {
    const timeZone = data.time_zone;

    if (!timeZone || timeZone === NTR.NO_ACCESS) {
      return `+0`;
    }

    return timeZone.indexOf(`-`) >= 0 ? timeZone : `+${timeZone}`;
  }

  static rulesLink(data) {
    const rulesLink = data.rules_link;

    if (!rulesLink || rulesLink === `null` || rulesLink === NTR.NO_ACCESS) {
      return ``;
    }

    return rulesLink;
  }

  static tariff(data) {
    const tariff = data.contest_tariff;

    if (tariff === NTR.NO_ACCESS) {
      return NTR.NO_ACCESS;
    }

    if (!tariff || !tariff.tariff || tariff.tariff === `0`) {
      return TariffMode.PROMO;
    }

    if (tariff.tariff === `1`) {
      return TariffMode.PLAYER;
    }

    if (tariff.tariff === `2`) {
      return TariffMode.PREMIUM;
    }

    return TariffMode.PROMO;
  }

  static demo(data) {
    const tariff = data.contest_tariff;

    if (!tariff) {
      return DemoMode.INACTIVE;
    }

    if (tariff === NTR.NO_ACCESS) {
      return NTR.NO_ACCESS;
    }

    const demo = tariff.demo;

    if (demo === NTR.NO_ACCESS) {
      return NTR.NO_ACCESS;
    }

    if (!demo || demo === `0` || demo === 0) {
      return DemoMode.INACTIVE;
    }

    if (demo === `1` || demo === 1) {
      return DemoMode.ACTIVE;
    }

    if (demo === `clear` || demo === `-1` || demo === -1) {
      return DemoMode.FINISHED;
    }

    return DemoMode.INACTIVE;
  }

  static contestServicePrice(data) {
    const PRICE_PER_PERFORMANCE_BASIC = 0;
    const price = data.contest_service_price;

    return (!price || price === NTR.NO_ACCESS) ? PRICE_PER_PERFORMANCE_BASIC : +price;
  }

  static currency(data) {
    const currency = data.currency;
    return (!currency || currency === NTR.NO_ACCESS) ? `руб.` : currency;
  }

  static contestServicePriceTariff(data) {
    // if (isTT()) {
    //   data.contest_service_price = `129`;
    // }
    const price = data.contest_service_price;

    if (!price || price === NTR.NO_ACCESS) {
      return ContestTariff.RATE_NONE;
    }

    const tariff = ContestTariffTable[price];

    return tariff || ContestTariff.RATE_NONE;
  }

  static paymentSystem(data) {
    const paymentSystem = data.payment_system;

    if (!paymentSystem || paymentSystem === NTR.NO_ACCESS || paymentSystem === NTR.EMPTY) {
      return {
        [PaymentRole.ORGANIZATOR]: ``,
        [PaymentRole.SERVER]: ``,
      };
    }

    return {
      [PaymentRole.ORGANIZATOR]: getPaymentSystem(paymentSystem[PaymentRole.ORGANIZATOR]),
      [PaymentRole.SERVER]: getPaymentSystem(paymentSystem[PaymentRole.SERVER]),
    };
  }

  static nominationTitle(data) {
    const parentId = `0`;
    const nomination = data.nomination_title;

    if (!nomination || nomination === NTR.NO_ACCESS) {
      return [];
    }

    const nominationIds = Object.keys(nomination);

    return nominationIds.map((nominationId) => {
      return {
        nominationId,
        title: nomination[nominationId],
        parentId,
      };
    });
  }

  static contestCriterias(data) {
    const criteria = data.criteria_title;

    if (!criteria || criteria === NTR.NO_ACCESS) {
      return [];
    }

    return Object.keys(criteria).map((key) => {
      const criteriaNomination = ContestParsing.criteriaNomination(data, key);

      return {
        criteriaId: (key[0] === `-`) ? key.slice(1) : key,
        title: ContestParsing.criteriaTitle(data, key),
        isPenalty: ContestParsing.criteriaIsPenalty(data, key),
        description: ContestParsing.criteriaNote(data, key),
        maxPoint: ContestParsing.criteriaMaxPoint(data, key),
        nominationId: criteriaNomination.nominationId,
        isConnect: criteriaNomination.isConnect,
        weight: ContestParsing.criteriaWeight(data, key),
      };
    });
  }

  static criteriaNomination(data, criteriaId) {
    const nomination = data.criteria_nomination;

    if (!nomination || nomination === NTR.NO_ACCESS || !nomination[criteriaId]) {
      return {
        nominationId: ``,
        isConnect: false
      };
    }

    return {
      nominationId: nomination[criteriaId].nomination_id || ``,
      isConnect: nomination[criteriaId].status === NTR.ONE,
    };
  }

  static criteriaTitle(data, criteriaId) {
    const title = data.criteria_title;

    return (!title || title === NTR.NO_ACCESS || !title[criteriaId]) ? `` : title[criteriaId];
  }

  static criteriaIsPenalty(data, criteriaId) {
    const title = data.criteria_title;

    if (!title || title === NTR.NO_ACCESS || !title[criteriaId]) {
      return false;
    }

    return criteriaId[0] === `-`;
  }

  static criteriaNote(data, criteriaId) {
    const note = data.criteria_note;

    return (!note || note === NTR.NO_ACCESS || !note[criteriaId]) ? `` : note[criteriaId];
  }

  static criteriaMaxPoint(data, criteriaId) {
    const maxPoint = data.criteria_max_points;

    return (!maxPoint || maxPoint === NTR.NO_ACCESS || !maxPoint[criteriaId]) ? `` : +maxPoint[criteriaId];
  }

  static criteriaWeight(data, criteriaId) {
    const weight = data.criteria_weight;

    return (!weight || weight === NTR.NO_ACCESS || !weight[criteriaId]) ? 100 : +weight[criteriaId];
  }

  static ageCategoryTitle(data) {
    const ageCategory = data.age_category_title;
    if (!ageCategory || ageCategory === NTR.NO_ACCESS) {
      return [];
    }

    const nominationIds = Object.keys(ageCategory);
    const ageCategories = [];

    nominationIds.forEach((nominationId) => {
      const ageCategoryIds = Object.keys(ageCategory[nominationId]);

      ageCategoryIds.forEach((ageCategoryId) => {
        ageCategories.push({
          ageCategoryId,
          title: ageCategory[nominationId][ageCategoryId],
          nominationId
        });
      });
    });

    ageCategories.sort((a, b) => +a.ageCategoryId - +b.ageCategoryId);

    return ageCategories;
  }

  static qualificationTitle(data) {
    const qualification = data.qualification_title;

    if (!qualification || qualification === NTR.NO_ACCESS) {
      return [];
    }

    const qualificationIds = Object.keys(qualification);

    return qualificationIds.map((qualificationId) => {
      return {
        qualificationId,
        title: qualification[qualificationId],
      };
    });
  }

  static smtp(data) {
    const smtp = data.smtp;

    if (!smtp || smtp === `null` || smtp === NTR.NO_ACCESS) {
      return {};
    }

    return {
      mailServer: smtp.mail_server,
      login: smtp.login,
      isAllContest: smtp.organization === NTR.ONE,
    };
  }

  static contestServerMail(data) {
    const smtp = data.smtp;

    if (!smtp || smtp === `null` || smtp === NTR.NO_ACCESS) {
      return {
        mailServer: null,
        login: null
      };
    }

    const contestServerMail = smtp.contest;

    if (!contestServerMail || contestServerMail === `null` || contestServerMail === NTR.NO_ACCESS) {
      return {
        mailServer: null,
        login: null
      };
    }
    // {"16":{"smtp":{"organization":{"mail_server":"4","login":"example@mail.ru"},"contest":{"mail_server":"4","login":"example@mail.ru"} } } }

    return {
      mailServer: contestServerMail.mail_server,
      login: contestServerMail.login,
    };
  }

  static organizationServerMail(data) {
    const smtp = data.smtp;

    if (!smtp || smtp === `null` || smtp === NTR.NO_ACCESS) {
      return {
        mailServer: null,
        login: null
      };
    }

    const orgaizationServerMail = smtp.organization;

    if (!orgaizationServerMail || orgaizationServerMail === `null` || orgaizationServerMail === NTR.NO_ACCESS) {
      return {
        mailServer: null,
        login: null
      };
    }

    return {
      mailServer: orgaizationServerMail.mail_server,
      login: orgaizationServerMail.login,
    };
  }

  static referee(data) {
    const referee = data.referee;

    if (!referee || referee === NTR.NO_ACCESS) {
      return [];
    }

    return Object.keys(referee).map((refereeId) => {
      if (typeof referee[refereeId] === `string`) {
        return RefereeModel.newReferee(refereeId, getNameFromText(referee[refereeId]));
      } else {
        return RefereeModel.newReferee(refereeId, referee[refereeId]);
      }
    });

    // const refereeIds = Object.keys(referee);

    // return refereeIds.map((refereeId) => {
    //   return RefereeModel.newReferee(refereeId, getNameFromText(referee[refereeId]));
    // });
  }

  static soundman(data) {
    const soundman = data.soundman;

    if (!soundman || soundman === NTR.NO_ACCESS) {
      return [];
    }

    const soundmanIds = Object.keys(soundman);

    return soundmanIds.map((soundmanId) => {
      return SoundmanModel.newSoundman(soundmanId, getNameFromText(soundman[soundmanId]));
    });
  }

  static role(data) {
    const roleData = data.role;

    if (!roleData || roleData === NTR.NO_ACCESS) {
      return [];
    }

    const peoples = [];

    const roles = Object.keys(roleData);
    roles.forEach((role) => {
      const rolePeoples = roleData[role];
      const roleIds = Object.keys(rolePeoples);

      roleIds.forEach((roleId) => {
        const people = rolePeoples[roleId];
        const peopleId = people.people_id;

        peoples.push({
          name: people.people_name,
          peopleId,
          role,
          roleId,
        });
      });
    });

    const peopleIdsSet = new Set();

    peoples.forEach((people) => {
      peopleIdsSet.add(people.peopleId);
    });

    const peoples2 = Array.from(peopleIdsSet).map((peopleId) => {
      const arr = peoples.filter((people) => people.peopleId === peopleId);

      const roles2 = arr.map((people) => {
        return {
          role: people.role,
          roleId: people.roleId,
        };
      });

      return {
        peopleId,
        name: arr[0].name,
        roles: roles2,
      };
    });

    return peoples2;
  }

  static compere(data) {
    const compere = data.compere;

    if (!compere || compere === NTR.NO_ACCESS) {
      return [];
    }

    const compereIds = Object.keys(compere);

    return compereIds.map((compereId) => {
      return CompereModel.newCompere(compereId, getNameFromText(compere[compereId]));
    });
  }

  static player(data) {
    const player = data.player;

    if (!player || player === NTR.NO_ACCESS) {
      return [];
    }

    return Object.keys(player).map((playerId) => {
      return {
        playerId,
        surname: player[playerId].surname,
        lastname: player[playerId].lastname,
        middlename: player[playerId].middlename,
      };
    });
  }

  static director(data) {
    const director = data.director;

    if (!director || director === NTR.NO_ACCESS) {
      return [];
    }

    return Object.keys(director).map((directorId) => {
      return {
        directorId,
        surname: director[directorId].surname,
        lastname: director[directorId].lastname,
        middlename: director[directorId].middlename,
      };
    });
  }

  static prizeOption(data) {
    let prize = data.prize_option;

    if (!prize || prize === NTR.NO_ACCESS) {
      return [];
    }

    return Object.keys(prize).map((prizeId) => {
      const prizeInfo = prize[prizeId].split(`|`);
      return {
        prizeId,
        title: prizeInfo[0] || ``,
        all: prizeInfo[1] || 0,
        selected: prizeInfo[2] || 0,
        ratingFrom: prizeInfo[3] || ``,
      };
    });
  }

  static contestPerformanceCity(data) {
    const city = data.performance_city;

    if (!city || city === NTR.NO_ACCESS) {
      return [];
    }

    const cityIds = Object.keys(city);

    return cityIds.map((cityId) => {
      return {
        cityId,
        title: city[cityId],
      };
    });
  }

  static contestDocument(data) {
    const document = data.contest_document;

    if (!document || document === NTR.NO_ACCESS) {
      return [];
    }

    const documentIds = Object.keys(document);

    const documents = documentIds.map((documentId) => {
      return {
        documentId,
        title: document[documentId].title,
        order: parsingOrder(document[documentId].order_by),
        isBase: getIsBaseDocument(document[documentId].title),
        type: getTypeDocument(document[documentId].title),
      };
    });

    return sortDocuments(documents);
  }

  static mailTemplates(data) {
    const template = data.mail_template;

    if (!template || template === NTR.NO_ACCESS) {
      return [];
    }

    const templateIds = Object.keys(template);

    const templates = templateIds.map((mailTemplateId) => {
      const boundId = template[mailTemplateId].contest_id;
      return {
        mailTemplateId,
        title: template[mailTemplateId].name,
        content: ``,
        isBase: boundId === NTR.ZERO,
        bound: boundId,
        boundId: Math.abs(+boundId),
        boundType: boundId.indexOf(`-`) === 0 ? `org` : `contest`,
      };
    });

    return sortMailTemplates(templates);
  }

  static terminal(data) {
    const terminal = data.terminal;

    if (!terminal || terminal === NTR.NO_ACCESS) {
      return [];
    }

    const terminalIds = Object.keys(terminal);

    return terminalIds.map((terminalId) => {
      let surname = terminal[terminalId].people_surname || ``;

      if (surname.indexOf(`~`) === 0) {
        surname = surname.slice(1);
      }

      return TerminalModel.newTerminal(terminalId, {
        surname,
        page: terminal[terminalId].page || ``
      });
    });
  }

  static landmarks(data) {
    const landmark = data.landmark_title;

    if (!landmark || landmark === NTR.NO_ACCESS) {
      return [];
    }

    const landmarkIds = Object.keys(landmark);

    return landmarkIds.map((landmarkId) => {
      return {
        landmarkId,
        id: landmarkId, // потом удалить
        title: landmark[landmarkId] || ``,
        timeStart: ContestParsing.landmarkTimeStart(data, landmarkId),
        source: ContestParsing.landmarkSource(data, landmarkId),
        sourceId: ContestParsing.landmarkSourceId(data, landmarkId),
        beforeTime: ContestParsing.landmarkBeforeTime(data, landmarkId),
      };
    });
  }

  static landmarkTimeStart(data, landmarkId) {
    const landmarkStart = data.landmark_start;

    if (!landmarkStart || landmarkStart === NTR.NO_ACCESS) {
      return 0;
    }

    const landmarkData = landmarkStart[landmarkId];

    if (!landmarkData || !landmarkData.landmark_time_start) {
      return 0;
    }

    return landmarkData.landmark_time_start;
  }

  static landmarkSource(data, landmarkId) {
    const landmarkStart = data.landmark_start;

    if (!landmarkStart || landmarkStart === NTR.NO_ACCESS) {
      return ``;
    }

    const landmarkData = landmarkStart[landmarkId];

    if (!landmarkData || !landmarkData.source) {
      return ``;
    }

    return landmarkData.source;
  }

  static landmarkSourceId(data, landmarkId) {
    const landmarkStart = data.landmark_start;

    if (!landmarkStart || landmarkStart === NTR.NO_ACCESS) {
      return ``;
    }

    const landmarkData = landmarkStart[landmarkId];

    if (!landmarkData || !landmarkData.source_id) {
      return ``;
    }

    return landmarkData.source_id;
  }

  static landmarkBeforeTime(data, landmarkId) {
    const beforeTime = data.before_time;

    if (!beforeTime || beforeTime === NTR.NO_ACCESS) {
      return 0;
    }

    const landmarkData = beforeTime[landmarkId];

    if (!landmarkData) {
      return 0;
    }

    return +landmarkData;
  }

  static performanceTimeAvail(data) {
    const timeAvail = data.performance_time_avail;

    if (!timeAvail || timeAvail === `null` || timeAvail === NTR.NO_ACCESS) {
      return ``;
    }

    return +timeAvail;
  }

  static performanceDurationSum(data) {
    const durationSum = data.performance_duration_sum;

    if (!durationSum || durationSum === `null` || durationSum === NTR.NO_ACCESS) {
      return ``;
    }

    return +durationSum;
  }

  static performancePause(data) {
    const pause = data.performance_pause;

    if (!pause || pause === `null` || pause === NTR.NO_ACCESS) {
      return null;
    }

    return +pause;
  }
}
